@import "../scss/icons.scss";
@import "../scss/config/modern/bootstrap.scss";
@import "../scss/config/modern/app.scss";
@import "../scss/config/modern/custom.scss";
@import "./shepherd.css";
@import "./mermaid.min.css";

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";
.reportClassification{
  display: flex;
  flex-direction: column;
  gap: 24px;

  .judul{
    display: flex;
    align-items: center;
    gap: 12px;

    p{
      font-size: 24px;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }

  .list{
    display: flex;
    gap: 24px;
  }

  .merah{
    width: 4px;
    height: 30px;
    background-color: #C70D0D;
  }
  .hijau{
    width: 4px;
    height: 30px;
    background-color: #05C38A;
  }
  .ungu{
    width: 4px;
    height: 30px;
    background-color: #8338EC;
  }
  .biru{
    width: 4px;
    height: 30px;
    background-color: #00A6FB;
  }
}

.appearOnMobile {
  display: none !important;
}

//Rigthsidebar offcanvas
// Complementary
.mobileTop {
  display: none;
}

.layoutComplementary {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.layoutList {
  display: flex;
  flex-direction: column;
}
.textH2 {
  font-size: 22px;
  color: #374151;
  font-weight: 700;
  margin: 0px;
}
.pe {
  margin-left: 10px;
}
.textComp {
  font-size: 14px;
  color: #374151;
  margin: 0px;
}
.listCardTest {
  margin: 30px 0px;
  display: flex;
  // justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
}
.imageTest {
  width: 250px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}
.informationTest {
  width: min-content;
}
.innerCardTest {
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.titleCardTest {
  font-size: 15px;
  color: #374151;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 18px;
}
.contentCardTest {
  font-size: 14px;
  color: #374151;
  margin-bottom: 24px;
  text-align: justify;
}
.buttonTest {
  border: none;
  width: max-content;
  padding: 12px 36px;
  background-color: #ff7518;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: 700;

  transition: background-color 0.3s linear;

  &:hover {
    color: white;
    background-color: #c15c17;
  }
}
.disabler {
  position: absolute;
  background-color: black;
  opacity: 0.25;
  width: 298px;
  height: 455px;
  border-radius: 12px;
  z-index: 1;
}

// Test Complementary
.fz24 {
  font-size: 24px;
}
.tC {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background-color: #eff3f4;
}
.tCLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  width: 300px;
  height: 100%;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.tCLeftTop {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 24px 0px 24px;
  gap: 30px;
}
.tCLeftTopDesc {
  height: 100%;
}
.tCLeftTopLine {
  background-color: #10b981;
  width: 4px;
  height: 52px * 4;
  position: absolute;
  margin-left: 10px;
  margin-top: 22px;
  z-index: 0;
}
.tCLeftTopKeterangan {
  font-size: 18px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 42px;
}
.tCLeftTopList {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.tCLeftTopType {
  display: flex;
  gap: 12px;
  z-index: 1;
  cursor: pointer;
  p {
    margin: 0px;
    font-size: 14px;
  }
}
.tCLeftBottom {
  img {
    width: 84px;
    height: 140px;
    position: absolute;
    bottom: 110px;
    left: 216px;
  }
  .bg {
    color: white;
    font-size: 12px;
    font-weight: 600;
    width: 300px;
    height: 190px;
    background: linear-gradient(111deg, #89bfff 13.88%, #4895ef 83.71%);
    border-bottom-right-radius: 24px;
    padding-top: 44px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.tCRight {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  // width: -webkit-fill-available;
}
.tCTopbar {
  display: flex;
  gap: 20px;
  padding: 24px 24px 24px 324px;
  background-color: #e6eced;
  .web {
    display: flex;
    align-items: center;
    img {
      width: 82px;
      height: 82px;
    }
    .title {
      // padding-top: 10px;
      font-size: 24px;
      font-weight: 700;
      margin: 0;
      color: #374151;
    }
  }
  .mob {
    display: none;
  }
}
.tCMain {
  padding: 24px 24px 0px 324px;
  min-height: 100%;
  .open {
    width: 450px;
    padding-top: 40px;
  }
  .info {
    margin-top: 60px;
    padding: 24px;
    border: 1px solid #9ca3af;
    border-radius: 12px;
    width: 800px;
    .ket {
      font-size: 18px;
      color: #374151;
      .title {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .waktu {
    font-size: 18px;
    font-weight: 700;
    margin-top: 42px;
    margin-bottom: 42px * 2;
  }
}
.soal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .fotoSoal {
    width: 380px;
  }
  .top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 18px;
    }
    .jam {
      background-color: #10b981;
      padding: 2px 20px;
      border-radius: 20px;
      color: white;

      transition: background-color 1s linear;
    }
  }
  .bottom {
    display: flex;
    width: 500px;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    .grup {
      .trans {
        opacity: 0;
        cursor: default;
      }
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
}
.listJawaban {
  display: flex;
  flex-wrap: wrap;
  width: 455px;
  justify-content: space-between;
  // gap: 34px;
  .jawaban {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px 12px 10px 14px;
    border: 2px solid #10b98100;
    border-radius: 10px;
    transition: 1s;
    input {
      display: none;
    }
    img {
      width: 115px;
    }
  }
  .click {
    border: 2px solid #10b981 !important;
  }
}
.listJawaban8 {
  display: flex;
  flex-wrap: wrap;
  width: 655px;
  justify-content: space-between;
  // gap: 34px;
  .jawaban {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 14px 12px 10px 14px;
    border: 2px solid #10b98100;
    border-radius: 10px;
    transition: 1s;
    input {
      display: none;
    }
    img {
      width: 115px;
    }
  }
  .click {
    border: 2px solid #10b981 !important;
  }
}
.testBerakhir {
  display: flex;
  flex-direction: column;
  gap: 30px;
  p {
    font-size: 36px;
    font-weight: 700;
  }
  img {
    width: 800px;
    align-self: center;
  }
  .end {
    align-self: self-end;
    margin-right: 20px;
  }
}

.redTime {
  background-color: #f43f5e !important;
}

.grupNumber {
  width: max-content;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto;
  gap: 10px;
  text-align: center;
  align-self: flex-start;
  position: absolute;
  margin-top: 55px;
}
.number {
  padding: 12px 18px;
  background-color: #10b981;
  border-radius: 6px;
  color: white;
  font-weight: 700;

  &.enabled {
    background-color: #007f5f;
  }
}
.inumber {
  padding: 12px 18px;
  background-color: #d1d5db;
  border-radius: 6px;
  color: white;
  font-weight: 700;

  &.enabled {
    background-color: #d1d5db;
  }
}

// Complentary END

.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  background-color: white;
  * {
    outline: none;
  }
}
.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}
.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
}
.noUi-handle {
  background: #6ada7d !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}

// ------- Mainan CSSnya disini ------- //

.prosessUI {
  height: 24px;
}

.material-symbols-outlined {
  color: grey;
}

.moreVert {
  padding: 0;
  margin: 0;
  display: flex;
  background-color: white;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: #eef6ff;
  }

  &:focus {
    .material-symbols-outlined {
      color: #1f599c;
      background-color: #eef6ff;
    }
  }
}

.dropzone .dz-message {
  text-align: center;
}
.fontJakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
}
.containterLogin {
  position: relative;
  z-index: 2;
  width: 100%;
}
.removePadding {
  padding: 0;
}
.kiri {
  // background-image: url("../images/FutureLink/kiri.png");
  // background-position: center;
  // background-repeat: no-repeat;
}
.navbar-menu {
  background: white;
  border-right: white;
}
.row-users-group {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  row-gap: 40px;
  column-gap: 20px;
}
.col-users-group {
  border-radius: 10px;
  padding: 20px 30px;
  width: 420px;
  box-shadow: 0px 2px 5px #0000001a, 0px 0px 2px #00000033;
}

.profileTitle {
  display: flex;
  justify-content: space-between;
  margin-left: 240px;
  margin-bottom: 20px;
}

.verifikasi {
  position: absolute;
  background-color: #f59e0b;
  padding: 4px 6px;
  margin-top: 3px;
  margin-right: 4px;
  border-radius: 3px;
  color: white;
  width: max-content;
  font-weight: 500;
  cursor: pointer;
}

.verifikasiValid {
  position: absolute;
  background-color: #10b981;
  padding: 4px 6px;
  margin-top: 3px;
  margin-right: 4px;
  border-radius: 3px;
  color: white;
  width: max-content;
  font-weight: 500;
}

.upgradePlease {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  color: #d19100;
}

.prevNext {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.bottomButton {
  width: 140px;
  padding: 15px 0px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
}

.btnRadius{
  padding: 12px 0px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}

.buttonVersFutureLink {
  background-color: #1f599c;
  color: white;
  border: none;

  &:hover {
    background-color: #154a86;
    color: white;
  }
}

.buttonVersFutureLinkGreen {
  background-color: #10b981;
  color: white;
  border: none;

  &:hover {
    background-color: #0ba370;
    color: white;
  }
}

.buttonVersFutureLinkOrange {
  width: 150px;
  padding: 15px 0px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  background-color: #ff7518;
  color: white;
  border: none;

  &:hover {
    background-color: #ff9130;
    color: white;
  }
}

.buttonVersFutureLinkTeal {
  background-color: #14b8a6;
  color: white;
  border: none;

  &:hover {
    background-color: #0d9488;
    color: white;
  }
}

.buttonVersFutureLinkRed {
  background-color: #f43f5e;
  color: white;
  border: none;

  &:hover {
    background-color: rgb(204, 68, 90);
    color: white;
  }
}

.buttonVersFutureLinkBorderGrey {
  background-color: white;
  border: 1px solid #c7c7c7;
  color: #4b5563;

  &:hover {
    color: white;
    background-color: #c7c7c7;
  }
}

.waktuCouns {
  width: 95px;
}

.headerConfigCounselor {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
}
.headerConfigCounselorText {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
}
.hariHari {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}
.mainList {
  display: flex;
  align-items: center;

  p {
    margin: 0px;
    font-size: 14px;
  }
}
.mainWaktu {
  display: flex;
  gap: 15px;
}

.purchase {
  display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  justify-content: flex-start;
  width: 450px;
  border: 3px solid white;
  border-radius: 20px;
  z-index: -1;
  box-shadow: 0px 0px 3.5753426551818848px 0px rgba(0, 0, 0, 0.1);
}

.imgPurchase {
  width: 100px;
  height: 100px;
  margin-left: 20px;
}

.titleReport {
  margin: 0px;
  background-color: #1f599c;
  padding: 8px 80px;
  font-size: 18px;
  font-weight: 900;
  color: white;
  border-radius: 10px;
  width: max-content;
}

@media screen and (max-width: 992px) {
  .responsiveGone {
    display: none;
    height: 1px;
  }
}
.news {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  margin: 30px 0px;
}

.newsList {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.newsItem {
  width: 30%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.newsMenu {
  flex: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.listArticle {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cardArticle {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  // width: max-content;
}

.articleImage {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.articleKeterangan {
  padding: 18px;
}

.articleJudul {
  font-size: 18px;
  margin: 0px;
}

.articleTanggal {
  font-size: 14px;
  margin: 0px;
}

.spotlightItem {
  display: flex;
  width: max-content;
  justify-content: flex-end;
  cursor: pointer;
}

.spotlightXMark {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  margin-right: 30px;
  margin-top: 17px;
  cursor: pointer;
}

.spotlightPreview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // overflow-x: scroll;
  width: 100%;
}

.artikelHeader {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 30px;
}

.buttonHeader {
  font-size: 24px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff7518;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#statuse {
  display: inline-block;
  vertical-align: middle;
}

.arrowHijau {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #0f71731a;
  position: relative;
  left: 90px;
}
.kontenHijau {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  position: relative;
  right: 280px;
  top: 10px;
}

.kontenKuning {
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  width: max-content;
  position: relative;
  top: 90px;
  right: 65px;
  transform: rotate(180deg);
}

.arrowKuning {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #ffbe0b1a;
  transform: rotate(180deg);
}

.arrowOrange {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #fb56071a;
  position: relative;
  right: 90px;
}

.arrowBiru {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #3a86ff1a;
  transform: rotate(180deg);
  position: relative;
  left: 90px;
}

.arrowUngu {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #8338ec1a;
}

.arrowMerah {
  width: 0;
  height: 0;
  border-left: 110px solid transparent;
  border-right: 110px solid transparent;
  border-bottom: 180px solid #ff006e1a;
  transform: rotate(180deg);
  position: relative;
  right: 90px;
}

.profileCover {
  background: linear-gradient(135deg, #5efce8 10%, #736efe 100%);
  width: 100%;
  height: 160px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 5px solid white;
}

.profilePicture {
  border: 5px solid white;
  margin: 80px 0px 0px 80px;
  box-shadow: 0px 25px 35px -15px rgba(75, 75, 75, 0.3);
}

.profileWhiteSpace {
  background-color: white;
  width: 100%;
  height: 120%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 5px solid white;
  border-top: none;
  border-image: initial;
  padding: 20px;
}

.modalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: center;
}

.alertGreen {
  background-color: #dcfce7;
  color: #10b981;
  border: 1px solid #10b981;
}

.alertYellow {
  background-color: #fef3c7;
  color: #f59e0b;
  border: 1px solid #f59e0b;
}

.alertRed {
  background-color: #fff1f2;
  color: #e11d48;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #e11d48;
}

.alertBlue {
  background-color: #dbeafe;
  color: #3b82f6;
  border: 1px solid #3b82f6;
}

.fade {
  opacity: 1;
  -webkit-transition: opacity 0.7s ease-in-out;
  -moz-transition: opacity 0.7s ease-in-out;
  -ms-transition: opacity 0.7s ease-in-out;
  -o-transition: opacity 0.7s ease-in-out;
  transition: opacity 0.7s ease-in-out;
}

.fadeout {
  opacity: 0;
}

// New Dashboard V2 For Client
.dashboardv2 {
  display: flex;
  // overflow: hidden;
  background-color: #eff3f4;
  .fullwidth {
    width: 100%;
  }
}
.pan{
  height: 100vh;
}
.dastit{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    margin-bottom: 0px !important;
  }

  a{
    color: #374151;
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 14px;
    cursor: pointer;

    p{
      margin: 0px;
    }
  }
}
.ac {
  color: #4895ef !important;
  fill: #4895ef !important;
}
.sidebarv2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  width: 300px;
  height: 100%;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 60px 38px 60px 20px;

  -webkit-box-shadow: 0px 0px 48px -16px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 48px -16px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 48px -16px rgba(0, 0, 0, 0.1);

  .menusidebarv2 {
    display: flex;
    flex-direction: column;
    gap: 80px;

    ul {
      margin: 0 !important;
      padding: 0 !important;

      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    li {
      a {
        display: flex;
        align-items: center;
        gap: 18px;
        text-decoration: none !important;
        font-size: 16px;
        font-weight: 700;

        color: #374151;
        fill: #374151;
        .active {
          background-color: #4895ef !important;
        }

        .line {
          background-color: white;
          width: 4px;
          height: 40px;
        }

        transition: 0.5s;

        &:hover {
          color: #4895ef;
          fill: #4895ef;
          transition: 0.5s;
        }
      }
      list-style: none;
    }
  }

  .buttonsidebarv2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    button {
      background: none;
      border: none;
    }
    .profilebuttonsidebarv2 {
      display: flex;
      align-items: center;
      gap: 18px;
      font-size: 18px;
      font-weight: 600;
      color: #374151;
      fill: #737373;

      &:hover {
        color: #4895ef;
        fill: #4895ef;
        transition: 0.5s;
      }
    }
  }
}

.hedButMo {
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 600;
  color: dimgray;
  fill: dimgray;
}

.pesan {
  // padding: 40px 0px !important;
  width: 100%;
  padding: 40px 0px !important;
  background-color: #e6eced;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
  }

  .hedMo {
    display: none;
  }

  button {
    background: none;
    border: none;
  }
  h4 {
    font-weight: 400;
  }
  nav {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  .kontakKami {
    background-color: #1f599c;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 28px;
    border-radius: 40px; 

    &:disabled{
      background-color: #213e60 !important;
      color: grey !important
    }
  }
}

.normal {
  width: 100%;
  padding: 40px 0px 40px 310px;
  background-color: #e6eced;

  .head {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;

    .hedMo {
      display: none;
    }

    button {
      background: none;
      border: none;
    }
    h4 {
      font-weight: 400;
    }
    nav {
      display: flex;
      align-items: center;
      gap: 18px;
    }
    .kontakKami {
      background-color: #1f599c;
      color: white;
      font-size: 14px;
      font-weight: 500;
      padding: 8px 28px;
      border-radius: 40px;
    }
  }
}
.mainv2 {
  display: flex;
  padding-left: 310px;
  .partLeft {
    display: flex;
    flex-direction: column;
    row-gap: 80px;

    h2 {
      color: #374151;
      margin-bottom: 10px;
    }
    .kerjasamaKami {
      align-items: center;
    }
  }

  // img{
  //   width: 100%;
  // }

  .space {
    justify-content: space-between;
  }

  .cardTestimoni {
    background-color: white;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
  }
  .cardTestimoniChild {
    background-color: white;
    align-items: center;
    padding: 5px;
    border-radius: 12px;
  }
}
.proLi {
  justify-content: space-evenly;
  gap: 12px;
  margin-top: 40px;

  p {
    font-size: 14px;
    margin-bottom: 14px;
    color: #4b5563;
  }
  input {
    font-size: 14px;
    border-bottom: 1px solid #9ca3af;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    color: #4b5563;
    padding-bottom: 12px;
  }
}
.pCrd {
  display: flex;
  padding: 20px;
  border: 1px solid #e3e8ef;
  background-color: #ffffff29;
  border-radius: 12px;
  align-items: center;
  gap: 20px;
  img {
    width: 80px;
    border-radius: 12px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  span {
    margin: 0px;
    font-size: 14px;
  }
}
.backgroundPurchase {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: #eff3f4;
  // background-image: url('../images/FutureLink/ornamen.svg');
}
.backgroundConfirm {
  width: -webkit-fill-available;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eff3f4;
  gap: 70px;
  // background-image: url('../images/FutureLink/ornamen.svg');
}
.menungguBayar {
  display: flex;
  align-items: center;
  gap: 24px;

  img {
    width: 200px;
  }
  p {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
  }
}
.pisahhan {
  display: flex;
  gap: 120px;
}
.piw2 {
  width: 350px;
}
.mw {
  margin-top: 18px;
  margin-bottom: 18px;
}
.butDis {
  display: flex;
  background: white;
  border: 2px solid #10b981;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
}
.linKodeDiskon {
  p {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
  }
  .gC {
    color: #10b981;
  }
}

.bds {
  display: flex;
  align-items: center;
  gap: 14px;
}
.blueunderline {
  margin: 0px;
  font-size: 12px;
  color: #1f599c;
  text-decoration: underline;
  font-weight: 600;
}
.linCheckbox {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 8px;
  p {
    margin: 0px;
    font-size: 12px;
    color: #4b5563;
  }
}

.linTo {
  margin: 36px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;

}
.linTo1 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  p {
    color: #677489;
  }
  .p {
    font-weight: 600;
    color: #111729;
  }
}
.linToS {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 12px;
  font-weight: 900 !important;
  p {
    color: #111729 !important;
  }
}

.pLe {
  width: 40%;
  padding: 50px;
  height: 90vh;
  z-index: 10;
}

.pRi {
  width: 40%;
  padding: 50px;
  height: 90vh;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inE {
  margin-bottom: 18px;
  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  input {
    width: 100%;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
  }
}
.paybayar {
  .pw {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }
  .listPayBay {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .listPBIt {
    display: flex;
    background-color: white;
    border: 2px solid white;
    border-radius: 12px;
    align-items: center;
    padding: 14px;
    gap: 18px;
    input {
      width: 18px;
      height: 18px;
    }
    img {
      width: 48px;
    }
    div {
      .p1 {
        margin: 0px;
        font-size: 14px;
        font-weight: 600;
        color: #666666;
      }
      .p2 {
        margin: 0px;
        color: #666666;
        font-size: 12px;
      }
    }
  }
}

.gone {
  display: none;
}

.pesan {
  padding: 40px 0px !important;
}

.cardProfile {
  width: 100%;
  height: 100%;
  padding: 100px 32px;
  background-color: white;
  border-radius: 24px;
}
.proCircle {
  border-radius: 100%;
  width: 150px;
  height: 150px;
  padding: 0px;
  object-fit: cover;
}
.ve {
  display: flex;
  justify-content: flex-end;
}
.corev2 {
  width: 100%;
  justify-content: space-between;
  padding: 50px 40px 150px 40px;
  overflow-x: auto;
}

.rlHeader {
  background-color: #1f599c;
  padding: 18px;
  color: white;
  text-align: center;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  font-size: 24px;
  font-weight: 600;
}
.rlContent {
  background-color: #d2deeb;
  text-align: -webkit-center;
  padding: 40px 15%;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}
.repCen {
  justify-content: center;
  height: 85vh;
  overflow-x: auto;
}

.antitank {
  margin: 0px;
}

.width77 {
  width: 77% !important;
}

.hargaPur {
  margin-bottom: 24px;
  padding: 6px 22px;
  background-color: #799bc4;
  color: white;
  font-size: 18px;
  font-weight: 600;
  width: max-content;
  border-radius: 20px;
}

.ketPur {
  font-size: 16px;
  color: #374151;
  margin-bottom: 24px;
}

.cardcom {
  padding: 35px 25px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rotiKep {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
  color: #9ca3af;
  font-size: 18px;
  font-weight: 600;
}

.bold {
  color: #374151;
}

.polaris {
  display: flex;
}
.polaLeft {
  display: flex;
  width: auto;
  gap: 20px;
}
.polaW {
  display: flex;
  gap: 70px;
}
.polaRight {
  width: auto;
}
.polaList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.polasImg {
  object-fit: cover;
  width: 75px;
  height: 75px;
}
.polaprog {
  height: 32px;
  width: 200px;
  background-color: none;
}
.polaLine {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.polaLineItem {
  padding: 32px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.sizeWeight {
  font-size: 18px;
  font-weight: 600;
}
.polKet {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pK {
  display: flex;
  align-items: flex-end;
}
.plKt {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p{
    font-size: 16px;
  }
}
.hijauw {
  width: 18px;
  height: 18px;
  background-color: #05c38a;
}
.kuningw {
  width: 18px;
  height: 18px;
  background-color: #ffd740;
}
.merahw {
  width: 18px;
  height: 18px;
  background-color: #ef6806;
}
.polTB {
  display: flex;
  flex-direction: column;
}
.polT {
  display: flex;
  align-items: center;
  background-color: #d2deeb66;
  padding: 20px 40px;
  gap: 24px;
  ul {
    margin: 0px;
  }
  li {
    font-size: 16px;
  }
}
.pU {
  display: flex;
  padding: 20px 15px;
  gap: 12px;

  p{
    font-size: 16px;
  }
}
.polLi {
  width: 2px;
  height: auto;
  background-color: #4b5563;
}
.polB {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.disT {
  justify-content: space-between;
}
.disB {
  justify-content: space-between;
}
.dbIm {
  width: 70px;
}
.dbKet {
  margin: 0px;
  width: 100%;
  font-size: 16px;
  text-align: justify;
}
.dbTit {
  margin: 0px;
  font-size: 22px;
  font-weight: 700;
}
.dbL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  // padding: 0px 10%;
}

.trLv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: center;
  height: 120px;
  border: 1px solid #E3E3E3;
}

.trLvKet {
  margin: 0px;
  word-wrap: break-word;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 12px;
  width: 100%;
}

.tfWB {
  font-size: 18px;
  font-weight: 500;
  padding: 12px 0px;
  color: white;
  text-align: center;
  margin: 0px;
  background-color: #1F599C;
  border: 1px solid #E3E3E3;
}

.trVa {
  display: flex;
  margin-top: 24px;
}

.trVaB {
  display: flex;
  padding: 8px 16px;
  border-radius: 15px;
  width: max-content;
  align-items: center;
  gap: 20px;
}

.trVaBT {
  margin: 0px;
  font-size: 16px;
  font-weight: 800;
}

.trVaBN {
  margin: 0px;
  padding: 5px 20px;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
}

.dom {
  height: 90vh;
  display: flex;
}

.doNi {
  display: flex;
  justify-content: center;
}
.doBut {
  display: flex;
  justify-content: flex-end;
}

.doVi {
  font-size: 16px;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.visimg {
  width: -webkit-fill-available;
  width: -moz-fill-available;
  position: absolute;
  bottom: 0px;
  right: 0;
  padding-left: 300px;
  height: 50%;
  object-fit: cover;
}

.eklis {
  display: flex;
  align-items: center;
  gap: 80px
}

.inIm {
  max-width: 20%;
}

.in {
  height: 110vh;
  display: flex;
  row-gap: 40px;
}

.gaBerIm {
  max-height: 120px;
}

.gaber {
  height: 90vh;
  display: flex;
  row-gap: 40px;
  justify-content: center;
  align-content: center;
}

.pow {
  height: 36px;
  width: 400px;
  // background-color: white;
}

.kecerdasaanCSS {
  display: flex;
  align-items: center;
  height: 70vh;
  width: -webkit-fill-available;
  justify-content: space-between;
  padding: 0px;

  &::before {
    // Buat garis tengah horizontal
    content: "";
    position: absolute;
    z-index: 1;
    background-color: #1f599c;
    height: 2px;
    width: 100%;
  }

  .itTop {
    width: 70px;
    height: 70px;
    // background-color: #4895ef;
    // border-radius: 100%;
    display: flex;
    z-index: 4;
    flex-direction: column-reverse;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 50px;
      background-color: #1f599c;
      margin: 0px 0px 76px 0px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 45px;
      height: 45px;
      border-top-left-radius: 100%;
      margin-bottom: 36px;
      margin-right: 43px;
      z-index: 2;
      background-color: #1f599c;
    }

    .kecmaNum {
      font-size: 21px;
      background-color: #4895ef;
      border-radius: 100%;
      font-weight: 900;
      color: white;
      padding: 22px 26px;
      margin: 0px;
      z-index: 5;
    }

    .kecmaOrb {
      width: 13px;
      height: 13px;
      border-radius: 100%;
      background-color: #1f599c;
      position: absolute;
      margin-bottom: 120px;
    }

    .kecmaImg {
      position: absolute;
      margin-bottom: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .kecmaBox {
        width: 135px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .kecmaKet {
          font-size: 16px;
          font-weight: 600;
        }

        .kecmaIsi {
          margin: 0px;
          text-align: center;
          font-size: 16px;
        }
      }

      img {
        max-width: 52px;
      }
      .kecP {
        margin: 0px;
        font-size: 14px;
        font-weight: 800;
        color: #05c38a;
      }
    }
  }

  .itBot {
    width: 70px;
    height: 70px;
    display: flex;
    z-index: 4;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 50px;
      background-color: #1f599c;
      margin: 75px 0px 0px 0px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 45px;
      height: 45px;
      border-bottom-right-radius: 100%;
      margin-top: 40px;
      margin-left: 44px;
      z-index: 2;
      background-color: #1f599c;
    }

    .kecmaNum {
      font-size: 21px;
      background-color: #4895ef;
      border-radius: 100%;
      font-weight: 900;
      color: white;
      padding: 22px 24px;
      margin: 0px;
      z-index: 5;
    }

    .kecmaOrb {
      width: 13px;
      height: 13px;
      border-radius: 100%;
      background-color: #1f599c;
      position: absolute;
      margin-top: 120px;
    }

    .kecmaImg {
      position: absolute;
      margin-top: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .kecmaBox {
        width: 135px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .kecmaKet {
          margin: 0px;
          font-size: 16px;
          font-weight: 600;
        }

        .kecmaIsi {
          text-align: center;
          font-size: 16px;
        }
      }

      img {
        max-width: 52px;
      }
      .kecP {
        margin: 0px;
        font-size: 14px;
        font-weight: 800;
        color: #05c38a;
      }
    }
  }
}
.koTi {
  display: flex;
  justify-content: center;
  gap: 14px;
}

.koLef {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
}

.koRig {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.koJu {
  font-size: 14px;
  margin: 0px;
  font-weight: 600;
}

.koPro {
  height: 36px;
  width: 250px !important;
}

.koKet {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-top: 48px;

  .layer1 {
    display: flex;
    gap: 48px;
  }

  .layer2 {
    flex: 1;
  }
}
.tewKet {
  font-size: 16px;
  text-align: justify;
}
.tallH {
  height: 90vh;
}
.gbVx {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  gap: 10px;
}
.gbD {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.kecPMo {
  margin: 0px;
  font-size: 14px;
  font-weight: 800;
  color: #05c38a;
}
.kecmaKetMo {
  font-size: 14px;
  font-weight: 600;
}
.kecmaIsiMo {
  margin: 0px;
  text-align: center;
  font-size: 12px;
}
.sumJu {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  place-self: center;
  margin: 0px;
}
.smKt {
  font-size: 14px;
  text-align: justify;
  font-weight: 400;
}
.sumS1 {
  display: flex;
  align-items: center;
  gap: 24px;
}
.smJ {
  font-size: 18px;
  font-weight: 600;
}
.smUl {
  font-size: 14px;
}
.smBox {
  display: flex;
  justify-content: center;
  margin-top: 50px
}
.sBx {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  justify-content: center;
  width: 40%;
  gap: 14px;
}
.smTit {
  font-size: 14px;
  margin: 0px;
  font-weight: 800;
  padding: 8px 12px;
  color: white;
  border-radius: 14px;
}
.smTrf {
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
}
.smTrV {
  margin: 0px;
  font-size: 24px;
  font-weight: 900;
}
.smTrKt {
  text-align: center;
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  width: 60%;
}

.suBt {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
.sumLe {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sumTo {
  display: flex;
  align-items: center;
}
.sumx1 {
  flex: 1;
}
.scus {
  background: linear-gradient(
    180deg,
    #13355e 0%,
    #19477d 19.59%,
    #1f599c 33.95%,
    #4c7ab0 48.62%,
    #799bc4 64.04%,
    #a5bdd7 78.88%,
    #d2deeb 100%
  );
  width: 70%;
  border-radius: 8px;
  padding: 16px 24px;
}
.scusp1 {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 0px;
}
.scusp2 {
  margin: 0px;
  color: white;
  font-size: 12px;
}
.smX {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scxa {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scxap1 {
  font-size: 18px;
  font-weight: 600;
  background-color: white;
  padding: 8px 24px;
  border-radius: 12px;
  border: 2px solid #4895ef;
  color: #4895ef;
  margin: 0px;
}
.scxap2 {
  font-size: 32px;
  font-weight: 900;
  color: #10b981;
  margin: 0px;
}
.sumx2 {
  flex: 1;
  display: flex;
}
.sumBo {
  display: flex;
  align-items: center;
}
.sumRi {
  width: 30%;
}
.sumRiBox {
  padding: 12px;
  border: 1px solid #799bc4;
  border-radius: 8px;
}
.sumRip1 {
  font-size: 14px;
  font-weight: 500;
}
.sumRip2 {
  font-size: 12px;
  text-align: justify;
  margin: 0px;
}
.dasArIm {
  width: 100%;
  margin-bottom: 6px;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
}

.dontsee{
  display: none;
}

.sumToc{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
}

// .animate {
//   animation-name: move;
//   animation-iteration-count: infinite;
//   animation-duration: 3s;

//   width: 100px;
//   height: 100px;
//   background-color: #fa7820;
//   border-radius: 10px;
//   position: relative;
// }

// @keyframes move {
//   0%   { left: 0px; }
//   50%  { left: 30%; }
//   100% { left: 0px; }
// }

// .itm {
// max-width: 30%;
// }
// #sidebar-menu {
//   ul {
//     li {
//       a {
//         i {
//           min-width: 2rem;
//         }
//       }
//     }
//   }
// }

// .leaflet-pane {
//   z-index: 1;
// }
// svg > rect:first-child {
//   fill-opacity: 0 !important;
// }
// .dataTables_filter,
// .dataTables_paginate {
//   float: right;
// }
// .rdw-editor-main {
//   border: 1px solid $gray-300;
//   height: 239px;
//   padding-left: 15px;
// }
// .dz-message {
//   text-align: center;
//   padding: 30px;
// }
// // kanban board
// .react-kanban-column {
//   height: 100%;
//   min-height: 28px;
//   display: inline-block;
//   padding: 15px;
//   border-radius: 2px;
//   background-color: rgb(238, 238, 238);
//   margin: 5px;
//   vertical-align: top;
// }
// .fcYNFc,
// .sc-AxmLO.gmtmqV {
//   background-color: $card-bg !important;
//   margin: 0 20px 0 0 !important;
// }
// .task-box {
//   border: 1px solid $border-color;
// }
// .react-datepicker-wrapper {
//   width: 100% !important;
// }
// .ReactModal__Overlay {
//   z-index: 1001 !important;
// }
// .external-event {
//   &:hover {
//     cursor: pointer;
//   }
// }
// a:hover {
//   cursor: pointer;
// }
// .rating-container {
//   background-color: transparent !important;
// }
// .input-group-append {
//   z-index: 0;
// }
// .input-color {
//   color: $input-color !important;
// }
// .sketch-picker {
//   position: absolute;
//   z-index: 1;
// }
// .rangeslider__fill {
//   background-color: $primary !important;
// }

// .dropdown-menu-end {
//   left: auto !important;
//   right: 0 !important;
// }

// .bs-select select {
//   width: auto !important;
// }

// //File-manager
// .file-manager {
//   .table td {
//     padding: 0.35rem;
//   }
// }

// //Authenication carousel
// .slider_css {
//   .slide {
//     background: none !important;
//   }

//   .carousel-status,
//   .control-next,
//   .control-prev {
//     display: none !important;
//   }
// }
// .carousel {
//   .control-dots {
//     margin: -5px 0px;
//   }
//   .dot {
//     background: #556ee6 !important;
//     width: 9px !important;
//     height: 9px !important;
//   }
// }

// //Two verification Page
// .verification {
//   input {
//     &:focus {
//       border: none;
//       outline: none !important;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .verification {
//     input {
//       width: 40px !important;
//     }
//   }
// }

// @media (max-width: 320px) {
//   .verification {
//     input {
//       width: 30px !important;
//       height: 30px !important;
//       font-size: 15px !important;
//     }
//   }
// }

// //Two verification Page - 2
// .verification-2 {
//   input {
//     &:focus {
//       border: none;
//       outline: none !important;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .verification-2 {
//     input {
//       width: 40px !important;
//       font-size: 14px;
//     }
//   }
// }

// @media (max-width: 320px) {
//   .verification-2 {
//     input {
//       width: 30px !important;
//       height: 35px !important;
//       font-size: 15px !important;
//     }
//   }
// }

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}
.flatpickr-calendar.open {
  z-index: 1090 !important;
}
.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}
.ql-container.ql-snow {
  height: 250px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #5ea3cb !important;
}

.Toastify__toast {
  min-height: 43px !important;
  max-height: 43px !important;
}

// //Chat Scrollbar
// .ps__rail-y {
//   z-index: 999999 !important;
// }
// .close {
//   background: transparent;
//   border: 0;
//   font-size: 10px;
//   padding: 1.35rem 1.25rem;
//   background: transparent escape-svg($btn-close-bg) center / $btn-close-width
//     auto no-repeat;
//   position: absolute;
//   top: 0;
//   right: 0;
//   opacity: 0.5;
//   width: 1em;
//   height: 1em;
//   z-index: 2;
//   span {
//     display: none;
//   }
// }

// //
// // Columns
// //

// .card-columns {
//   .card {
//     margin-bottom: $grid-gutter-width;
//   }

//   @include media-breakpoint-up(sm) {
//     column-count: 3;
//     column-gap: 1.25rem;
//     orphans: 1;
//     widows: 1;

//     .card {
//       display: inline-block; // Don't let them vertically span multiple columns
//       width: 100%; // Don't let their width change
//     }
//   }
// }

// /* drawer */
// .react-drawer-drawer {
//   z-index: 9999;
//   background: #fff;
// }
// .drawer-main-menu {
//   padding-top: 8px;
//   padding-bottom: 8px;
// }
// .drawer-menu a i {
//   min-width: 2rem;
//   display: inline-block;
//   min-width: 1.75rem;
//   padding-bottom: 0.125em;
//   font-size: 1.25rem;
//   line-height: 1.40625rem;
//   vertical-align: middle;
//   color: #7f8387;
//   transition: all 0.4s;
// }
// .drawer-menu a {
//   display: block;
//   padding: 0.625rem 1.5rem;
//   color: #545a6d;
//   position: relative;
//   font-size: 13px;
//   transition: all 0.4s;
// }
// .drawer-close {
//   padding: 0.5rem 0.5rem;
//   margin: -0.5rem -0.5rem -0.5rem auto;
// }
// .ReactModal__Overlay {
//   z-index: 5000 !important;
// }
// .rangeslider__handle:focus {
//   outline: none;
// }
// // React Table next css
// .react-bootstrap-table-page-btns-ul {
//   float: right !important;
// }
// .search-label {
//   float: right;
// }
// .react-drawer-overlay {
//   z-index: 9999;
// }

// // datatable

// .react-bs-table-sizePerPage-dropdown {
//   .dropdown-menu {
//     .dropdown-item {
//       a {
//         display: block;
//       }
//     }
//   }
// }
// .rightbar-title .right-bar-toggle {
//   background-color: #444c54 !important;
//   height: 24px !important;
//   width: 24px !important;
//   line-height: 24px !important;
//   display: block !important;
//   color: #eff2f7 !important;
//   text-align: center !important;
//   border-radius: 50% !important;
// }

// //rightbar drawer
// .css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
//   animation-duration: 0.6s !important;
// }
// .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
//   background: rgba(0, 0, 0, 0.15) !important;
// }

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

// chat
.chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

// Authentication pages
#tsparticles {
  canvas {
    position: absolute !important;
  }
}

// ------- Report UI ------- //

.red {
  color: #f43f5e;
}

.yellow {
  color: #f59e0b;
}

.green {
  color: #10b981;
}

.blue {
  color: #3b82f6;
}

.reportItem {
  border: 1px solid grey;
  border-radius: 18px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: white;
  height: 140px;
  justify-content: space-evenly;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.05);
}

.reportList {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.reportItemMo {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  gap: 5%;
}

.reportListMobile {
  display: none;
}

// # Pola Sidik Jari Page # //
.polasidikjariList {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // width: max-content;
}

.polasidikjariItem {
  display: flex;
  align-items: center;
  width: max-content;

  .polasidikjariBoxImg {
    border: 1px solid #05c38a;
    padding: 10px;
    border-radius: 10px;

    .polasidikjariImg {
      object-fit: cover;
      width: 120px;
      height: 120px;
    }
  }
}
.noPadding{
  padding: 0px !important;

  h4{
    text-align: center;
  }
}
.dFlexColumn{
  display: flex !important;
  flex-direction: column !important;
}

.prosessidik{

}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  margin: 10px;
}
.grid-container-avail {
  display: grid;
  grid-template-columns: auto auto;
  // margin: 0% 30% 0% 30%;
  align-items: center;
  justify-content: center;
}
.img-avail {
  width: 400px;
}
.grid-container-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.grid-item {
  padding: 20px;
  font-size: 30px;
  margin-bottom: 40px;
}
.q-title {
  display: flex;
  margin: auto;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid;
  border-color: #4895ef;
  padding-left: 20px;
  padding-right: 20px;
}
.card-gradient {
  padding: 20px;
  background-image: linear-gradient(#13355e, #d2deeb);
  width: 300px;
  border-radius: 10px;
}
.center-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.end-item {
  display: flex;
  align-items: end;
  flex-direction: column;
}
.card-tfrc {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid;
  padding: 20px;
  max-width: 300px;
}
.card {
  // width: 80%;
  .card-header {
    padding: 20px;
    border-radius: 20px 20px 0px 0px;
    background-color: #1f599c;
  }

  .card-body {
    border-radius: 0px 0px 20px 20px;
    background-color: #d2deeb;
  }
}
.card-title {
  // padding: 20px;
  // border-radius: 20px 20px 0px 0px;
  background-color: #1f599c;
  color: white;
  text-align: center;
}
.hand-pattern {
  width: 200px;
}
@media screen and (max-width: 800px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    padding: 0px !important;
    margin: 0px !important;
  }
  .grid-container-avail {
    display: grid;
    grid-template-columns: auto;
    // margin: 0% 30% 0% 50%;
    justify-content: center;
    align-items: center;
  }
  .img-avail {
    width: 300px;
  }
}
@media screen and (max-width: 1250px) {
  .hand-pattern {
    width: 100px;
  }
}

@media screen and (min-width: 1420px) {
  .hand-pattern {
    width: 100px;
  }
}

// # Distribusi Neuron Page # //
.distribusineuron {
  padding-left: 0px;
  padding-right: 0px;
}
.distribusineuronMap {
  width: 100%;
}
.distribusineuronList {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 80px;

  .collection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .value {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .keterangan {
        margin: 0px;
        width: 400px;
        font-size: 14px;
        text-align: justify;
      }
    }
  }
}

.distribusineuronItemTitle {
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
}

.prosesberpikirList {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 0px 10%;
}

// # TRFC # //
.trfc {
  padding-left: 0px;
  padding-right: 0px;
}

.trfcKeterangan {
  font-size: 14px;
  text-align: justify;
}

.trfcValue {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.trfcValueBox {
  display: flex;
  padding: 15px 60px;
  border-radius: 15px;
  width: max-content;
  align-items: center;
  gap: 20px;
}

.trfcValueBoxTitle {
  margin: 0px;
  font-size: 22px;
  font-weight: 800;
}

.trfcValueBoxNumber {
  margin: 0px;
  padding: 5px 20px;
  color: white;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 800;
}

.trfcWithBorder {
  font-size: 24px;
  font-weight: 900;
  color: #4895ef;
  text-align: center;
  border: 2px solid #4895ef;
  margin: 0px;
  border-radius: 10px;
}

.trfcWithBorderNone {
  font-size: 24px;
  font-weight: 900;
  color: #4895ef;
  text-align: center;
  border: 2px solid #4895ef;
  margin: 0px;
  border-radius: 10px;
}

.trfcLevel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  color: white;
  text-align: center;
  height: 75px;
}

.trfcLevelKeterangan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  height: 120px;
  word-break: break-all;
  border: 1px solid #E3E3E3;
}

.trfcLevelKeteranganText {
  margin: 0px;
  word-wrap: break-word;
  text-align: justify;
  font-size: 16px;
  font-weight: 700;
  padding: 0px 20px;
  width: 100%;
}

.level1p {
  color: #007f5f;
}

.level2p {
  color: #2b9348;
}

.level3p {
  color: #55a630;
}

.level4p {
  color: #80b918;
}

.level5p {
  color: #849E01;
}

.level6p {
  color: #FF7518;
}

.level7p {
  color: #FF533F;
}

.level1b {
  border: 2px solid #007f5f;
}

.level2b {
  border: 2px solid #2b9348;
}

.level3b {
  border: 2px solid #55a630;
}

.level4b {
  border: 2px solid #80b918;
}

.level5b {
  border: 2px solid #849E01;
}

.level6b {
  border: 2px solid #FF7518;
}

.level7b {
  border: 2px solid #FF533F;
}

.level1 {
  background-color: #007f5f;
}

.level2 {
  background-color: #2b9348;
}

.level3 {
  background-color: #55a630;
}

.level4 {
  background-color: #80b918;
}

.level5 {
  background-color: #aacc00;
}

.level6 {
  background-color: #bfd200;
}

.level7 {
  background-color: #d4d700;
}

.level5tfrc{
  background-color: #849E01;
}
.level6tfrc{
  background-color: #FF7518;
}
.level7tfrc{
  background-color: #FF533F;
}

.trfcMobile {
  display: none;
}

// # Dominasi Otak # //
.dominasiotak {
  padding-left: 0px;
  padding-right: 0px;
}

.dominasiotakKeterangan {
  font-size: 16px;
  text-align: justify;
  margin: 0px;
  line-height: 28px;
}

.dominasiotakContainter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 50px;
}

.dominasiotakContainterReverse {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 50px;
}

.dominasiotakContainterBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// # Gaya Belajar # //
.gayabelajar {
  padding-left: 0px;
  padding-right: 0px;
}

.gayabelajarList {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.gayabelajarVisual {
  display: flex;
  gap: 60px;
}

.gayabelajarKinesthetic {
  display: flex;
  gap: 60px;
}

.gayabelajarAuditory {
  display: flex;
  gap: 20px;
}

.gayabelajarBoxValue {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  gap: 10px;
}

.gayabelajarBoxValueMobile {
  display: none;
}

.gayaBelajarKeterangan {
  font-size: 16px;
  width: 400px;
  text-align: justify;
}

.gayaBelajarVisual {
  width: 300px;
}

.gayaBelajarKinesthetic {
  width: 400px;
}

.gayaBelajarAuditory {
  width: 435px;
}

// # ekstrakulikuler # //

.ekstrakulikuler {
  padding-left: 0px;
  padding-right: 0px;
}

.ekstrakulikulerList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.ekstrakulikulerListItem {
  display: flex;
  align-items: center;
}

.ekstrakulikulerListText {
  margin: 0px;
  padding-top: 4px;
  width: 100px;
  font-size: 14px;
  font-weight: 700;
}

.ekstrakulikulerListCollection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ekstrakulikulerBox {
  width: 50px;
  height: 25px;
}

.level8 {
  background-color: #d7b500;
}

.level9 {
  background-color: #d78e00;
}

.level10 {
  background-color: #d74d00;
}

// # Penjurusan # //

.penjurusan {
  padding-left: 0px;
  padding-right: 0px;
}

.penjurusanList {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.penjurusanBulat {
  width: 140px;
  background-color: #005096;
  color: white;
  padding: 58px 0px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  border-radius: 100%;
}

.penjurusanWebMobile {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.penjurusanKeterangan {
  margin-top: 80px;
}

.penjurusanBorder {
  margin: 0px;
  padding: 5px 25px;
  border: 2px solid #4895ef;
  border-radius: 15px;
  color: #1f599c;
  font-size: 24px;
  font-weight: 900;
  width: max-content;
}

// # Lobus Otak # //

.lobusotak {
  padding-left: 0px;
  padding-right: 0px;
}

.lobusotakGraph {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lobusotakKiri {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 80px;
  position: relative;
  left: 50px;

  .lobusotakKiriText {
    display: flex;
    flex-direction: column;
    align-items: center;

    .kiriText1 {
      font-size: 18px;
      font-weight: 900;
      color: #6431b1;
    }

    .kiriText2 {
      font-size: 36px;
      font-weight: 900;
      color: #6431b1;
    }
  }

  .lobusotakKiriLine {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .rounds {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: #6431b1;
    }

    .lineLobusPrefrontal {
      width: 60px;
      height: 2px;
      background-color: #6431b1;
    }
  }
}

.lobusotakTengah {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
}

.lobusotakAtas {
  display: flex;
  position: relative;
  right: 40px;
}

.lobusotakAtas1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  position: relative;
  top: 35px;
  right: 10px;

  .atas1Text1 {
    font-size: 36px;
    font-weight: 900;
    color: #7129aa;
  }

  .atas1Text2 {
    font-size: 18px;
    font-weight: 900;
    color: #7129aa;
  }

  .lobusotakAtas1Line {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rounds {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: #7129aa;
    }

    .lineLobusFrontal {
      width: 2px;
      height: 30px;
      background-color: #7129aa;

      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 45px;
        height: 2px;
        background-color: #7129aa;
      }
    }
  }
}

.lobusotakAtas2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  position: relative;
  left: 25px;

  .atas2Text1 {
    font-size: 36px;
    font-weight: 900;
    color: #6e9b00;
  }

  .atas2Text2 {
    font-size: 18px;
    font-weight: 900;
    color: #6e9b00;
  }

  .lobusotakAtas2Line {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rounds {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: #6e9b00;
    }
  }
}

.lobusotakImg {
  width: 400px;
}

.lobusotakBawah {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  position: absolute;
  top: -150px;
  left: -80px;
  // top: 590px;

  .lobusotakBawahLine {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rounds {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: #0966da;
    }

    .lineLobusTemporal {
      width: 2px;
      height: 200px;
      background-color: #0966da;
    }
  }

  .bawahText1 {
    font-size: 18px;
    font-weight: 900;
    color: #0966da;
  }

  .bawahText2 {
    font-size: 36px;
    font-weight: 900;
    color: #0966da;
  }
}

.lobusotakKanan {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 60px;
  position: relative;
  right: 10px;

  .lobusotakKananLine {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .rounds {
      width: 12px;
      height: 12px;
      border-radius: 100px;
      background-color: #3939aa;
    }

    .lineLobusOksipital {
      width: 40px;
      height: 2px;
      background-color: #3939aa;
    }
  }

  .lobusotakKananText {
    display: flex;
    flex-direction: column;
    align-items: center;

    .kananText1 {
      font-size: 18px;
      font-weight: 900;
      color: #3939aa;
    }

    .kananText2 {
      font-size: 36px;
      font-weight: 900;
      color: #3939aa;
    }
  }
}

.lobusotakContent {
  margin-top: 180px;
}

// # Kecerdasan # //

.kecerdasan {
  padding-left: 0px;
  padding-right: 0px;
}

.kecerdasanList {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.kecerdasanMain {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  .imgMain {
    width: 700px;
  }
}

.kecerdasanMobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
  }
}

.kecerdasanIQ {
  margin-bottom: 80px;

  .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    left: 70%;
    top: 175px;

    .p1 {
      margin: 0px;
      padding: 5px 50px;
      border: 2px solid #4895ef;
      border-radius: 15px;
      color: #1f599c;
      background-color: white;
      font-size: 24px;
      font-weight: 900;
      width: max-content;
    }

    .p2 {
      margin: 0px;
      font-size: 32px;
      font-weight: 900;
      color: #05c38a;
    }
  }
}

.kecerdasanEQ {
  .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    left: 70%;
    top: 175px;

    .p1 {
      margin: 0px;
      padding: 5px 50px;
      border: 2px solid #4895ef;
      border-radius: 15px;
      color: #1f599c;
      background-color: white;
      font-size: 24px;
      font-weight: 900;
      width: max-content;
    }

    .p2 {
      margin: 0px;
      font-size: 32px;
      font-weight: 900;
      color: #05c38a;
    }
  }
}

.kecerdasanAQ {
  margin-bottom: 80px;

  .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    left: 70%;
    top: 175px;

    .p1 {
      margin: 0px;
      padding: 5px 50px;
      border: 2px solid #4895ef;
      border-radius: 15px;
      color: #1f599c;
      background-color: white;
      font-size: 24px;
      font-weight: 900;
      width: max-content;
    }

    .p2 {
      margin: 0px;
      font-size: 32px;
      font-weight: 900;
      color: #05c38a;
    }
  }
}

.kecerdasanCQ {
  margin-bottom: 80px;

  .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    left: 70%;
    top: 175px;

    .p1 {
      margin: 0px;
      padding: 5px 50px;
      border: 2px solid #4895ef;
      border-radius: 15px;
      color: #1f599c;
      background-color: white;
      font-size: 24px;
      font-weight: 900;
      width: max-content;
    }

    .p2 {
      margin: 0px;
      font-size: 32px;
      font-weight: 900;
      color: #05c38a;
    }
  }
}

// # Indra Dasar # //
.indradasar {
  padding-left: 0px;
  padding-right: 0px;
}

.indradasarIntro {
  text-align: justify;
  font-size: 14px;
}

.indradasarList {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.indradasarItem {
  display: flex;
  align-items: center;
  gap: 40px;
}

.indradasarImg {
  width: 230px;
}

// # Gaya Berpikir # //

.gayaberpikir {
  padding-left: 0px;
  padding-right: 0px;
}

.gayaberpikirIntro {
  text-align: justify;
  font-size: 14px;
}

.gayaberpikirMobile {
  display: none;
}

.gayaberpikirList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 80px;
}

.gayaberpikirItemKiri {
  display: flex;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;

  .itemBox {
    display: flex;
    flex-direction: column;
    height: max-content;
  }
}

.gayaberpikirItemKanan {
  display: flex;
  gap: 40px;
  justify-content: flex-end;

  .khusus {
    display: flex;
    flex-direction: column;
  }

  .affective {
    width: 400px;
  }

  .itemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
  }
}

// # Minat Kejuruan # //

.minatkejuruan {
  padding-left: 0px;
  padding-right: 0px;
}

.minatkejuruanIntro {
  display: flex;
  flex-direction: column;
}

.minatkejuruanKeterangan {
  display: none;
  margin-top: 20px;
  font-size: 14px;
  text-align: justify;
}

// # Kecerdasan Majemuk # //

.kecerdasanmajemuk {
  padding-left: 0px;
  padding-right: 0px;
}

.kecerdasanmajemukWeb {
  display: flex;
  flex-direction: column;
}

.expert {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 150px;

  &::before {
    content: "";
    position: absolute;
    z-index: -3;
    background-color: #1f599c;
    width: 2px;
    height: 1000px;
  }
}
.itemKanan {
  width: 70px;
  height: 70px;
  background-color: #4895ef;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .number {
    margin: 0px;
    font-size: 24px;
    font-weight: 900;
    color: white;
  }
  .text {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: 310px;
    gap: 5px;
    height: 150px;
    margin-top: 70px;

    .keterangan {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .isi {
      font-size: 14px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 240px;
    height: 2px;
    margin-left: 310px;
    z-index: -2;
    background-color: #1f599c;
  }
  &::after {
    content: "";
    position: absolute;
    width: 45px;
    height: 45px;
    border-top-right-radius: 100%;
    margin-bottom: 45px;
    margin-left: 45px;
    z-index: -1;
    background-color: #1f599c;
  }
  .rounds {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #1f599c;
    border-radius: 100%;
    margin-left: 550px;
  }
  .imgValue {
    position: absolute;
    margin-left: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 55px;
    }

    p {
      margin: 0px;
      font-size: 24px;
      font-weight: 700;
      color: #05c38a;
    }
  }
}
.itemKiri {
  width: 70px;
  height: 70px;
  background-color: #4895ef;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .number {
    margin: 0px;
    font-size: 24px;
    font-weight: 900;
    color: white;
  }
  .text {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 310px;
    gap: 5px;
    height: 150px;
    margin-top: 70px;

    .keterangan {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .isi {
      font-size: 14px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 240px;
    height: 2px;
    margin-right: 310px;
    z-index: -2;
    background-color: #1f599c;
  }
  &::after {
    content: "";
    position: absolute;
    width: 45px;
    height: 45px;
    border-top-left-radius: 100%;
    margin-bottom: 45px;
    margin-right: 45px;
    z-index: -1;
    background-color: #1f599c;
  }
  .rounds {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #1f599c;
    border-radius: 100%;
    margin-right: 550px;
  }
  .imgValue {
    position: absolute;
    margin-right: 630px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 55px;
    }

    p {
      margin: 0px;
      font-size: 24px;
      font-weight: 700;
      color: #05c38a;
    }
  }
}

.kecerdasanmajemukMobile {
  display: none;
}

.kecerdasanmajemukMobileList {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .kecerdasanmajemukMobileItem {
    .judul {
      font-size: 18px;
      font-weight: 700;
    }
    .keterangan {
      margin-top: 8px;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

// # Domain Kecerdasan # //

.domainkecerdasan {
  padding-left: 20px;
  padding-right: 20px;
}

.domainkecerdasanMobile {
  display: none;
  padding-left: 0px;
  padding-right: 0px;
}

// # DISC # //

.disc {
  padding-left: 0px;
  padding-right: 0px;
}

.discWeb {
  display: flex;
  flex-direction: column;
}

.discGambar {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: flex-end;
  margin: 50px 0px;
}

.discMobile {
  display: none;
}

// # DOPE # # //
.dope {
  padding-left: 0px;
  padding-right: 0px;
}

.dopeWeb {
  display: flex;
  flex-direction: column;
}

.dopeIntro {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;

  .listImg {
    display: flex;
    gap: 40px;
  }

  .valueBox {
    display: flex;
    align-items: center;
    border: 2px solid #4895ef;
    border-radius: 20px;
    padding: 20px 30px;
    gap: 30px;
  }
}

.dopeItem {
  margin: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.dopeMobile {
  display: none;
}

// # Konsep Pemikiran # //
.konseppemikiran {
  padding-left: 0px;
  padding-right: 0px;
}

.konseppemikiranWeb {
  display: flex;
  flex-direction: column;
}

.konseppemikiranMobile {
  display: none;
}

.konseppemikiranMobileList {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .konseppemikiranMobileItem {
    .judul {
      font-size: 18px;
      font-weight: 600;
    }
    img {
      width: 80px;
      margin: 20px 0px;
    }
    .isi {
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      margin-top: 20px;
    }
  }
}

.mirror {
  display: inline-block;
  font-size: 30px;

  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.readArticle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 10%;
  // user-select: none; fungsinya buat ga bisa copas
}
.readArticle h1 {
  font-size: 32px;
  font-weight: 600;
}

.readArticle h5 {
  font-size: 16px;
  margin-bottom: 30px;
}

.readArticle img {
  width: 40%;
}
@media screen and (max-width: 1165px) {
  .distribusineuronList {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 80px;

    .collection {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 30px;

      .item {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .value {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .keterangan {
          margin: 0px;
          width: 100%;
          font-size: 16px;
          line-height: 28px;
          text-align: justify;
        }
      }
    }
  }

  .trfcWithBorderNone {
    display: none;
  }

  .trfcKeterangan {
    display: none;
  }

  .trLv {
    height: 75px;
  }

  .tfWB{
    display: none;
  }

  .trfcLevelKeterangan {
    display: none;
  }

  .trfcMobile {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
  }

  .gayabelajarVisual {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .gayabelajarKinesthetic {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .gayabelajarAuditory {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .gayabelajarBoxValue {
    display: none;
  }

  .gayabelajarBoxValueMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    gap: 10px;
  }

  .ekstrakulikulerListCollection {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 24px;
  }

  .ekstrakulikulerBox {
    width: 25px;
    height: 10px;
  }

  .lobusotakImg {
    width: 200px;
  }

  .lobusotakKiri {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-top: 120px;
    position: relative;
    left: 50px;

    .lobusotakKiriText {
      display: flex;
      flex-direction: column;
      align-items: center;

      .kiriText1 {
        font-size: 14px;
        font-weight: 900;
        color: #6431b1;
        margin: 0px;
      }

      .kiriText2 {
        font-size: 24px;
        font-weight: 900;
        color: #6431b1;
      }
    }

    .lobusotakKiriLine {
      display: flex;
      align-items: center;
      padding-top: 8px;

      .rounds {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #6431b1;
      }

      .lineLobusPrefrontal {
        width: 20px;
        height: 2px;
        background-color: #6431b1;
      }
    }
  }

  .lobusotakAtas1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    top: 35px;
    right: -20px;

    .atas1Text1 {
      font-size: 24px;
      font-weight: 900;
      color: #7129aa;
      margin: 0px;
    }

    .atas1Text2 {
      font-size: 14px;
      font-weight: 900;
      color: #7129aa;
    }

    .lobusotakAtas1Line {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rounds {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #7129aa;
      }

      .lineLobusFrontal {
        width: 2px;
        height: 30px;
        background-color: #7129aa;

        &::after {
          content: "";
          position: absolute;
          bottom: 0px;
          width: 20px;
          height: 2px;
          background-color: #7129aa;
        }
      }
    }
  }

  .lobusotakAtas2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: relative;
    left: 25px;

    .atas2Text1 {
      font-size: 24px;
      font-weight: 900;
      color: #6e9b00;
      margin: 0px;
    }

    .atas2Text2 {
      font-size: 14px;
      font-weight: 900;
      color: #6e9b00;
    }

    .lobusotakAtas2Line {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rounds {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #6e9b00;
      }
    }
  }

  .lobusotakBawah {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: absolute;
    top: -80px;
    // top: 450px;

    .lobusotakBawahLine {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rounds {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #0966da;
      }

      .lineLobusTemporal {
        width: 2px;
        height: 80px;
        background-color: #0966da;
      }
    }

    .bawahText1 {
      font-size: 14px;
      font-weight: 900;
      color: #0966da;
      margin: 0px;
    }

    .bawahText2 {
      font-size: 24px;
      font-weight: 900;
      color: #0966da;
    }
  }

  .lobusotakKanan {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-top: 60px;
    position: relative;
    right: 30px;

    .lobusotakKananLine {
      display: flex;
      align-items: center;
      padding-top: 8px;

      .rounds {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        background-color: #3939aa;
      }

      .lineLobusOksipital {
        width: 20px;
        height: 2px;
        background-color: #3939aa;
      }
    }

    .lobusotakKananText {
      display: flex;
      flex-direction: column;
      align-items: center;

      .kananText1 {
        font-size: 14px;
        font-weight: 900;
        color: #3939aa;
      }

      .kananText2 {
        font-size: 24px;
        font-weight: 900;
        color: #3939aa;
      }
    }
  }

  .lobusotakContent {
    margin-top: 100px;
  }

  .kecerdasanMain {
    display: flex;
    flex-direction: column;

    .imgMain {
      width: 100%;
    }
  }

  .kecerdasanIQ {
    margin-bottom: 80px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 60%;
      top: 175px;

      .p1 {
        margin: 0px;
        padding: 5px 50px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanEQ {
    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 60%;
      top: 120px;

      .p1 {
        margin: 0px;
        padding: 5px 50px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanAQ {
    margin-bottom: 80px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 60%;
      top: 175px;

      .p1 {
        margin: 0px;
        padding: 5px 50px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanCQ {
    margin-bottom: 80px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 60%;
      top: 175px;

      .p1 {
        margin: 0px;
        padding: 5px 50px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }
}

@media screen and (max-width: 500px) {

  .titleReport {
    margin: 0px;
    background-color: #1f599c;
    padding: 8px 18px;
    font-size: 18px;
    font-weight: 900;
    color: white;
    border-radius: 10px;
    width: max-content;
  }

  .imgPurchase {
    width: 50px;
    height: max-content;
    margin-left: 20px;
  }

  .polasidikjariImg {
    object-fit: cover;
    width: 60px !important;
    height: 60px !important;
  }

  .progress-xl {
    height: 36px;
    background-color: white;
  }

  .width175{
    width: 175px !important;
  }

  .width300{
    width: 300px !important;
  }

  .trfcValueBoxTitle {
    margin: 0px;
    font-size: 18px;
    font-weight: 800;
  }

  .trfcValueBoxNumber {
    margin: 0px;
    padding: 5px 20px;
    color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 800;
  }

  .ekstrakulikulerListText {
    margin: 0px;
    padding-top: 4px;
    width: 85px;
    font-size: 14px;
    font-weight: 700;
  }

  .ekstrakulikulerBox {
    width: 15px;
    height: 10px;
  }

  .penjurusanWebMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .gayaBelajarKeterangan {
    font-size: 16px;
    width: 100% !important;
    text-align: justify;
    line-height: 28px;
  }

  .gayaBelajarVisual {
    width: 50% !important ;
  }

  .gayaBelajarKinesthetic {
    width: 50% !important ;
  }

  .gayaBelajarAuditory {
    width: 80% !important ;
  }
}

@media screen and (max-width: 762px) {
  .pan{
    height: 145vh;
  }
  .spmTable {
    border-radius: 20px;
    overflow: hidden;
  }
  .sumJu{
    margin-bottom: 28px;
  }
  .suBt{
    gap: 0px !important;
  }
  .sumRi{
    width: 100% !important;
    margin-bottom: 50px;
  }
  .sumx2{
    flex-direction: column;
    gap: 32px;
  }
  .scus{
    width: 100%;
  }
  .sumBo{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
  }
  .sumx1{
    width: 100%;
  }
  .sumTo{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .sumLe {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .sumS1{
    display: flex;
    align-items: center;
    gap: 24px;
    flex-direction: column;
    margin-top: 24px;
  }
  .sBx {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    justify-content: center;
    width: 100% !important;
    gap: 14px;
    margin-bottom: 32px;;
  }
  .backgroundConfirm {
    height: 100vh !important;
  }

  .menungguBayar {
    flex-direction: column;
  }

  .piw2 {
    width: 100% !important;
  }

  .pisahhan {
    flex-direction: column;
    gap: 0px !important;
    padding: 0px 24px !important;
  }

  .backgroundPurchase {
    flex-direction: column;
  }

  .pLe {
    width: 100% !important;
    padding: 24px !important;
    height: auto !important;
    z-index: 0 !important;
  }

  .pRi {
    width: 100% !important;
    padding: 24px !important;
    height: 60vh !important;
  }

  .layer1 {
    display: flex;
    gap: 48px;
    flex-direction: column;
  }

  .koTi {
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-direction: column;
  }

  .discwd {
    width: 75% !important;
  }

  .iqszx {
    align-items: center !important;
    flex-direction: column;
  }
  .qx {
    height: 100% !important;
  }
  .kmLi {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pow {
    height: 28px;
    width: 200px;
  }

  .gaber {
    height: 100%;
    display: flex;
    row-gap: 40px;
    justify-content: center;
    align-content: flex-start;
  }

  .in {
    height: 100%;
  }

  .itm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gbD {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .dom {
    height: 100%;
    display: block;
  }

  .tallH {
    height: 100%;
  }

  .tiwtiw {
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
  }
  .bBek {
    position: absolute;
    left: 10px;
  }
  .polB {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-direction: column;
  }
  .polT {
    display: flex;
    align-items: center;
    background-color: #d2deeb66;
    padding: 20px 40px;
    gap: 24px;
    flex-direction: column;
  }
  .polaris {
    display: flex;
    gap: 54px;
  }
  .polaprog {
    height: 28px;
    width: 170px;
    background-color: none;
  }
  .polasImg {
    object-fit: cover;
    width: 45px;
    height: 45px;
  }

  .goneOnMobile {
    display: none !important;
  }
  .appearOnMobile {
    display: flex !important;
  }

  .sidebarv2 {
    display: none;
  }
  .mainv2 {
    padding-left: 0px !important;
    justify-content: center;
  }
  .corev2 {
    padding: 30px 20px 150px 20px !important;
  }

  .mooooIm {
    width: 100%;
  }

  .maBoMo {
    margin-bottom: 20px;
  }

  .cardTestimoniChild {
    background-color: white;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
  }

  .dashTesti {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 18px;
  }

  .dsC {
    position: absolute;
  }

  .dsJu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .repCen {
    justify-content: center;
    gap: 48px;
    // height: 100% !important;
    height: max-content !important;
  }

  .normal {
    padding: 40px 0px;
    background-color: white;
  }
  .hedTit {
    display: none !important;
  }
  .hedMo {
    display: flex !important;
    img {
      width: 50%;
    }
  }
  .hedAp {
    position: absolute;
    float: right;
    right: 0;
    background-color: white;
    z-index: 1;
    padding: 24px;
    margin-top: 36px;
    border-bottom-left-radius: 32px;
  }
  .hedApUl {
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 12px;
    font-weight: 600;
    list-style: none;
    padding-left: 0px;
    li {
      a {
        display: flex;
        align-items: center;
        gap: 14px;
        color: dimgray;
        fill: dimgray;
        img {
          width: 32px !important;
        }
      }
    }
  }

  .newsList {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 30px;
  }

  .newsItem {
    width: 100%;
    cursor: pointer;
  }

  .newsMenu {
    width: 100%;
    cursor: pointer;
  }

  .readArticle img {
    width: 75%;
  }

  .profileCover {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(135deg, #5efce8 10%, #736efe 100%);
    width: 100%;
    height: 160px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border: 5px solid white;
  }

  .profilePicture {
    border: 5px solid white;
    margin: 80px 0px 0px 0px;
    box-shadow: 0px 25px 35px -15px rgba(75, 75, 75, 0.3);
  }

  .profileTitle {
    visibility: hidden;
  }

  .groupDashboard {
    justify-content: center;
  }

  .reportList {
    padding-left: 20px;
    padding-right: 20px;
    display: none;
    flex-wrap: wrap;
    gap: 70px;
    justify-content: center;
  }

  .reportListMobile {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 0px 20px;

    .judul{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
  
      p{
        font-size: 24px;
        margin-bottom: 4px;
        font-weight: 700;
      }
    }
  
    .merah{
      width: 4px;
      height: 30px;
      background-color: #C70D0D;
    }
    .hijau{
      width: 4px;
      height: 30px;
      background-color: #05C38A;
    }
    .ungu{
      width: 4px;
      height: 30px;
      background-color: #8338EC;
    }
    .biru{
      width: 4px;
      height: 30px;
      background-color: #00A6FB;
    }

    .list{
      display: flex;
      flex-direction: column;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 3.57534px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 15px 20px;
      cursor: pointer;

      img {
        width: 40px;
      }

      p {
        margin: 0px;
        font-size: 18px;
        font-weight: 700;
        width: -webkit-fill-available;
        color: #374151;
      }
    }
  }

  .polasidikjariList {
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .polasidikjariItem {
    display: flex;
    align-items: center;
    width: max-content;

    .polasidikjariBoxImg {
      border: 1px solid #05c38a;
      padding: 10px;
      border-radius: 10px;

      .polasidikjariImg {
        object-fit: cover;
        width: 80px;
        height: 80px;
      }
    }
  }

  .distribusineuronItemTitle {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  .prosesberpikirList {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 0px;
  }

  .progress-xl {
    height: 36px;
    width: 400px;
    background-color: white;
  }

  .dominasiotakContainter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 50px;
    gap: 20px;
  }

  .dominasiotakContainterReverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 40px 50px;
    gap: 20px;
  }

  .dominasiotakContainterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .trfcValueBox {
    display: flex;
    padding: 15px 20px;
    border-radius: 15px;
    width: max-content;
    align-items: center;
    gap: 20px;
  }

  .penjurusanBulat {
    width: 120px;
    background-color: #005096;
    color: white;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
    border-radius: 100%;
  }

  .penjurusanKeterangan {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .kecerdasanMain {
    display: none;

    .imgMain {
      width: 100%;
    }
  }

  .kecerdasanMobile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .title {
      display: none;
    }
  }

  .kecerdasanIQ {
    margin-bottom: 80px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 50%;
      top: 125px;

      .p1 {
        margin: 0px;
        padding: 5px 25px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanEQ {
    margin-top: -56px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 50%;
      top: 85px;

      .p1 {
        margin: 0px;
        padding: 5px 25px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanAQ {
    margin-bottom: 80px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 50%;
      top: 55px;

      .p1 {
        margin: 0px;
        padding: 5px 25px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .kecerdasanCQ {
    margin-bottom: 54px;

    .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      position: relative;
      left: 50%;
      top: 20px;

      .p1 {
        margin: 0px;
        padding: 5px 25px;
        border: 2px solid #4895ef;
        border-radius: 15px;
        color: #1f599c;
        background-color: white;
        font-size: 24px;
        font-weight: 900;
        width: max-content;
      }

      .p2 {
        margin: 0px;
        font-size: 32px;
        font-weight: 900;
        color: #05c38a;
      }
    }
  }

  .indradasarIntro {
    display: none;
  }

  .indradasarItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  .indradasarImg {
    width: 150px;
  }

  .gayaberpikirList {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-top: 80px;
  }

  .gayaberpikirItemKiri {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    .selfCognitive {
      width: 150px;
    }

    .reflective {
      width: 150px;
    }

    .itemBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
    }
  }

  .gayaberpikirItemKanan {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    align-items: center;

    .khusus {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 40px;
    }

    .affective {
      width: 200px;
    }

    .reverse{
      width: 100px;
    }

    .itemBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
    }
  }

  .gayaberpikirIntro {
    display: none;
  }

  .gayaberpikirMobile {
    display: flex;
    text-align: justify;
    font-size: 16px;
    line-height: 28px;
    margin-top: 50px;
  }

  .minatkejuruanIntro {
    display: none;
  }

  .minatkejuruanKeterangan {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
  }

  .kecerdasanmajemukWeb {
    display: none;
  }

  .kecerdasanmajemukMobile {
    display: flex;
    flex-direction: column;
  }

  .domainkecerdasan {
    display: none;
    visibility: hidden;
    padding-left: 20px;
    padding-right: 20px;
  }

  .domainkecerdasanMobile {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-bottom: 40px;

      .rounds {
        background-color: #1f599c;
        border-radius: 100%;
        width: 280px;
        height: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .valueBox {
        .p1 {
          margin: 0px;
          font-size: 24px;
          font-weight: 600;
          color: #1f599c;
          border: 2px solid #799bc4;
          padding: 10px 20px;
          border-radius: 20px;
        }
        .p2 {
          margin: 0px;
          font-size: 32px;
          font-weight: 900;
          text-align: center;
          color: #10b981;
        }
      }
      .keterangan {
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
      }
    }
  }

  .discWeb {
    display: none;
  }

  .discMobile {
    display: flex;
    flex-direction: column;

    .discMobileType {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }

    .discRed {
      width: 200px;
    }

    .discYellow {
      width: 150px;
    }

    .discMobileValue {
      margin: 40px 0px;

      .p1 {
        margin: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #1f599c;
        border: 2px solid #799bc4;
        padding: 10px 20px;
        border-radius: 20px;
      }
      .p2 {
        margin: 0px;
        font-size: 24px;
        font-weight: 900;
        text-align: center;
      }
    }

    .discMobileKeterangan {
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
    }
  }

  .dopeWeb {
    display: none;
  }

  .dopeMobile {
    display: flex;
    flex-direction: column;

    .dopeMobileType {
      display: flex;
      flex-direction: column;
      align-items: center;

      .p1 {
        margin-top: 40px;
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 600;
        color: #1f599c;
        border: 2px solid #799bc4;
        padding: 10px 20px;
        border-radius: 20px;
      }

      .dopeMobileImg {
        margin: 20px 0px;
        width: 100px;
      }

      .p2 {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 900;
        text-align: center;
      }
    }
  }

  .konseppemikiranWeb {
    display: none;
  }

  .konseppemikiranMobile {
    display: flex;
    flex-direction: column;
  }
}

// Complementary Mobile
@media screen and (max-width: 1160px) {
.grupNumber{
  display: none;
}
}

@media screen and (max-width: 850px) {
  .grupNumber {
    width: max-content;
    display: none;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
    gap: 10px;
    text-align: center;
    align-self: start;
  }

  .mobileTop {
    display: flex;
    justify-content: space-between;
    column-gap: 2%;
    width: 100%;
    // width: -webkit-fill-available;
    border-radius: 50px;
    background-color: #e6eced;

    /* z-depth mobile */
    box-shadow: 0px 0px 3.57534px 0px rgba(0, 0, 0, 0.1);
    .clickable {
      background-color: transparent;

      &.active {
        background-color: #10b981 !important;
      }
    }
    .whiter {
      background-color: transparent !important;
      color: white !important;

      &.active {
        color: #10b981 !important;
      }
    }
    span {
      padding: 4px 8px;
      // background-color: #a5bdd7;
      color: white;
      border-radius: 50%;
    }
    p {
      // margin: 12px 8px;
      padding: 3% 5%;
      margin: 0px;
      border-radius: 50px;
    }
  }

  .tCLeft {
    display: none;
  }

  .tCTopbar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 24px;
    background-color: #fcfcfc;
    .web {
      display: none;
      img {
        width: 82px;
        height: 82px;
      }
      .title {
        // padding-top: 10px;
        font-size: 24px;
        font-weight: 700;
        margin: 0;
        color: #374151;
      }
    }
    .mob {
      display: flex;
      .title {
        // padding-top: 10px;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        color: #374151;
      }
    }
  }

  .tCMain {
    padding: 24px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .open {
      width: 90%;
      padding-top: 0px;
    }
    .info {
      width: 90%;
      margin-top: 20px;
      .ket {
        .title {
          font-size: 16px;
        }
        font-size: 14px !important;
      }
    }
    .waktu {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .soal {
    gap: 24px;
    .top {
      width: 100%;
      p {
        font-size: 14px;
      }
    }
    .fotoSoal {
      width: 75%;
    }
    .bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }

  .listJawaban {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    .jawaban {
      display: flex;
      flex-direction: column;
      gap: 12px;
      input {
        accent-color: green;
      }
      img {
        width: 64px;
      }
    }
  }

  .testBerakhir {
    display: flex;
    flex-direction: column;
    gap: 70px;
    p {
      font-size: 24px;
      font-weight: 700;
    }
    img {
      width: 80%;
      align-self: center;
    }
    .end {
      align-self: self-end;
      margin-right: 10px;
      display: none;
    }
  }

  .buttonTest {
    background-color: #1f599c;
    align-self: center;
    &:hover {
      color: white;
      background-color: #0b386b;
    }
  }

  .bola {
    padding: 18px 18px !important;
    border-radius: 50% !important;
  }

  .disapearText {
    display: none;
  }
}

.rowReport {
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.spmReport {
  margin: 0;
  background-color: #EFF3F4;
}

.spmTable {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.spmTbl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 900;
  color: white;
  text-align: center;
  height: 75px;
  // border-radius: 4px;
  overflow: hidden;
  p{
    margin: 0px;
  }

  &.title {
    background-color: #13355e;
  }
}

.spmKet {
  margin: 0px;
  word-wrap: break-word;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 0px 12px;
  width: 100%;
}

.title {
  // background-color: #13355e;
}

.lvl1 {
  background-color: #19477d;
}

.lvl2 {
  background-color: #1f599c;
}

.lvl3 {
  background-color: #4c7ab0;
}

.lvl4 {
  background-color: #799bc4;
}

.lvl5 {
  background-color: #a5bdd7;
}

.spm {
  margin: 10px;
  font-size: 20px;
  font-weight: 800;
}

.spmKat {
  background-color: #1f599c;
  font-size: 18px;
  font-weight: 800;
  color: white;
  text-align: center;
  width: max-content;
  height: 35.87px;
  margin: 6px 10px;
  border-radius: 10px;
  overflow: hidden;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.spmScore {
  font-size: 52px;
  font-weight: 700;
  color: #1f599c;
  text-align: Left middle;
  margin: 10px;
}

.spmKeterangan {
  margin: 10px;
  font-size: 15px;
  text-align: justify;
}

.bgReport {
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  z-index: 1;
  position: absolute;
}
